<template>
    <div class="zan">
        <div class="hd">
            <div class="tit">
                <Icon name="arrow-left" @click="$router.back(-1)" />
                点赞
            </div>
            <div class="tips">
                共3人点赞
            </div>
        </div>
        <div class="bd">
            <div class="item" v-for="(item, index) in zanlist" :key="index">
                <div class="pic">
                    <VanImage width="100%" height="100%" fit="cover" lazy-load  :src="require('@assets/logo.png')" />
                </div>
                <div class="rcon">
                    <div class="rconhd acea-row -wrapper">
                        <div>
                            <div>
                                <span class="username Ellipses1">好吃鬼</span>
                                <span class="grade bg-color">酒徒</span>
                            </div>
                            <div class="time">2小时前</div>
                        </div>
                        <div class="rgicon">
                            <span class="font-color border-color">关注</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Icon } from "vant";
    import { Image as VanImage } from 'vant';
    export default {
        name: "zan",
        data () {
            return {
                zanlist: [0,1,2]
            }
        },
        components: {
            VanImage, Icon
        },
        created() {
            
        },
        mounted: function() {
            
        },
        methods: {
            
        }
    }
</script>

<style scoped lang="scss">
    .zan{
        padding-top:1.6rem;font-size:0.24rem;
        .hd{
            position: fixed;top: 0;left: 0;right: 0;background: #ffff;z-index: 1;
            .tit{
                border-bottom: 1px solid #eee;height: 1rem;font-size: 0.28rem;text-align: center;line-height: 1rem;position: relative;
                i{float:left;float: left;line-height: 1rem;font-size: 0.4rem;padding: 0 0.2rem;position: absolute;top: 0;left: 0;}
            }
            .tips{font-size:0.24rem;padding: 0.1rem 0.2rem;color: #999;}
        }
        .bd{
            .item{
                margin:0.2rem 0.2rem 0;color:#999;
                .pic{float:left;width: 0.8rem;height: 0.8rem;border:1px solid #dedede;border-radius:1rem;overflow:hidden;}
                .rcon{
                    margin-left:1rem;
                    .rconhd{
                        padding: 0 0 0.2rem;border-bottom:1px solid #dedede;
                        .username{font-size:0.28rem;max-width:3rem;color:#333;float:left;}
                        .grade{float:left;padding: 0.05rem 0.2rem;border-radius:1rem;margin-left:0.1rem;}
                        .time{font-size:0.24rem;}
                        .rgicon{
                            position:relative;
                            span{border: 1px solid #dedede;border-radius:1rem;padding: 0.1rem 0.3rem;}
                        }
                    }
                }
            }
        }
    }
</style>